<template>
    <div class="lang-select-container custom-lang-select" v-if="currentLanguage && allLanguages && allLanguages.length > 1" @click="toggleDropdown">
        <div class="lang-dropdown" @blur="isOpen = false">
            <div class="lang-dropdown-selected" ref="dropdownSelected">
                {{ currentLanguageName }}
                <span class="lang-dropdown-arrow" :class="{ 'arrow-up': isOpen }">▾</span>
            </div>
            <transition name="fade">
                <div v-if="isOpen" class="lang-dropdown-menu">
                    <div v-for="lang in allLanguages" :key="lang.id" class="lang-dropdown-item"
                        @click.stop="selectLanguage(lang.id)">
                        {{ lang.name }}
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    data() {
        return {
            isOpen: false,
        };
    },
    computed: {
        ...mapGetters("app", ["currentLanguageName", 'currentLanguage', 'allLanguages']),
    },
    methods: {
        ...mapActions('app', ['changeLocale']),
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },
        selectLanguage(langId) {
            this.changeLocale(langId);
            this.isOpen = false;
        },
        closeDropdown(event) {
            if (!this.$el.contains(event.target)) {
                this.isOpen = false;
            }
        },
        handleScroll() {
            this.isOpen = false;
        },
    },
    mounted() {
        document.addEventListener('click', this.closeDropdown);
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.closeDropdown);
        window.removeEventListener('scroll', this.handleScroll);
    },
}
</script>

<style lang="scss" scoped>
.lang-select-container {
    position: relative;
    display: inline-block;
    /* border: 1px solid #ddd; */
    border-radius: 16px;
    padding: 8px 0px 8px 12px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    line-height: 24px;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #282E38;
    cursor: pointer;
    margin-right: 24px;
}

.lang-dropdown {
    cursor: pointer;
    user-select: none;
}

.lang-dropdown-selected {
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.lang-dropdown-arrow {
    margin-left: 10px;
    font-size: 25px;
    color: #666;
    user-select: none;
    position: relative;
    top: -2px;
}

.lang-dropdown-menu {
    position: absolute;
    left: 0;
    top: 110%;
    background-color: #fff;
    border: 1px solid #ddd;
    width: 100%;
    z-index: 1000;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.lang-dropdown-item {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.lang-dropdown-item:hover {
    background-color: #f0f0f0;
}

.lang-dropdown-arrow.arrow-up {
    transform: rotate(-180deg);
    top: 2px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>