<template>
  <div class="modal offer-modal">
    <div class="overlay"  @click="$emit('closeOfferDetailsModal')"></div>
    <div class="wrapper">
      <img class="close" @click="$emit('closeOfferDetailsModal')" src="./../assets/close.svg"/>
      <div class="container">
        <form>
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="title">{{ $t('Order') }} #{{$parent.orderToView.id}}</div>
              <div class="form-fields-wrapper">
                <div class="offer-wrapper">
                  <table>
                    <thead>
                      <tr>
                        <td>{{ $t('Skin name') }}</td>
                        <td>{{ $t('Quality') }}</td>
                        <td>{{ $t('Item count') }}</td>
                        <td>{{ $t('Price') }}</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{$parent.orderToView.title}}</td>
                        <td>{{$parent.orderToView.item_quality}}</td>
                        <td>{{$parent.orderToView.count}}</td>
                        <td>{{$parent.orderToView.sum}} {{$parent.currency}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'OfferDetailsModal',
  props: [],
  components: {
  },
  data: function() {
    return {
      
    }
  },
  methods: {
    closeOfferDetailsModal() {
      this.$emit('closeOfferDetailsModal')
    },
  }
}
</script>