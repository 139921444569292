<template>
  <div id="app" :class="['app ' + $route.name]">
    <transition name="fade">
      <div id="page" class="page" v-if="pageLoaded">
        <transition name="fade">
          <Menu v-if="menuVisible" @openSignInModal="closeMenu"/>
        </transition>
        <div class="mob menu-icon" @click="toggleMenu" :class="[{'active': menuVisible}, {'blured': signInModalIsVisible || offerDetailsModalIsVisible}]">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div :class="['header-container', {'active': signInModalIsVisible || offerDetailsModalIsVisible}]">
          <Header :cartContents="cartContents" @openSignInModal="openSignInModal" @openSaleSkinModal="openSaleSkinModal" @logout="logout" v-if="isAuth || (!isAuth && $route.name != 'Profile' && $route.name != 'Cart')"/>
        </div>
        <div :class="['view-container', {'active': signInModalIsVisible || offerDetailsModalIsVisible}]">
          <router-view @openOfferDetailsModal="openOfferDetailsModal" @formatDate="formatDate" :addToCartChosenItem="addToCartChosenItem" :currency="currency"/>
        </div>
        <div :class="['footer-container', {'active': signInModalIsVisible || offerDetailsModalIsVisible}]">
          <Footer v-if="isAuth || (!isAuth && $route.name != 'Profile' && $route.name != 'Cart')"/>
        </div>
        <transition name="fade">
          <SignInModal v-if="signInModalIsVisible" @closeSignInModal="closeSignInModal" @login="login" @registration="registration" @forgotPass="forgotPass"/>
        </transition>
        <transition name="fade">
          <OfferDetailsModal v-if="offerDetailsModalIsVisible" @closeOfferDetailsModal="closeOfferDetailsModal" :orderToView="orderToView"/>
        </transition>
        <transition name="fade">
          <SaleSkinModal v-if="saleSkinModalIsVisible" @closeSaleSkinModal="closeSaleSkinModal" />
        </transition>
        <cookie-modal></cookie-modal>
      </div>
    </transition>
  </div>
</template>

<script>
import CookieModal from './components/CookieModal.vue';
import Header from './components/Header.vue'
import Menu from './components/Menu.vue'
import Footer from './components/Footer.vue'
import SignInModal from './components/SignInModal.vue'
import OfferDetailsModal from './components/OfferDetailsModal.vue'
import SaleSkinModal from './components/SaleSkinModal.vue'
import {loadScriptsFromString} from "@/utils/load_scripts";
import { mapActions } from "vuex";

export default {
  name: 'app',
  components: {
    Header,
    Menu,
    Footer,
    SignInModal,
    OfferDetailsModal,
    SaleSkinModal,
    CookieModal
  },
  data: function() {
    return {
     pageLoaded: false,
     menuVisible: false,
     signInModalIsVisible: false,
     offerDetailsModalIsVisible: false,
     saleSkinModalIsVisible: false,
     nav: [
      {
        'title': 'Home',
        'link': '/home'
      },
      {
        'title': 'Products',
        'link': '/products'
      },
      {
        'title': 'NEW Arrivals',
        'link': '/new-arrivals'
      },
      {
        'title': 'Sale',
        'link': '/sale'
      }
     ],
      isAuth: false,
      error: '',
      successMessage: '',
      orderHistory: [],
      orderToView: [],
      addToCartChosenItem: '',
      newArrivals: [],
      recommendedProducts: [],
      saleProducts: [],
      cartContents: [],
      totalSum: '0.00',
      textPageTitle: '',
      textPageHeader: '',
      textPageContent: '',
      productPageItem: '',
      similarProducts: '',
      imgDomain: '',
      textPageList: '',
      currency: '',
      hostname: '',
      footerRequisites: '',
      footerSupportEmail: '',
      footerCopyright: ''
    }
  },
  watch: {
    $route() {
      window.scrollTo(0, 0);
      this.closeMenu();
      if (this.isAuth) {
        this.getOrderHistory();
      }
    }
  },
  methods: {
    ...mapActions('app',['fetchLanguages']),
    getGoogleTagManagerSnippet() {
      this.$http.get(process.env.VUE_APP_API + 'settings')
          .then((res) => {
            this.footerRequisites = res.data.payload.requisites;
            this.footerSupportEmail = res.data.payload.support_email;
            this.footerCopyright = res.data.payload.copyright;
            const snippet = res.data.payload.google_tag;
            if (snippet) {
              loadScriptsFromString(snippet);
            }
          })
          .catch((error) => {
            console.error("Error fetching Google Tag Manager snippet:", error);
          });
    },
    getDomainName(hostName) {
      return hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
    },
    closeMenu() {
      this.menuVisible = false;
      document.body.style.overflow = "auto";
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
      window.scrollTo(0, 0);
      if (this.menuVisible) {
        document.body.style.overflow = "hidden";
      } else {
         document.body.style.overflow = "auto";
      }
    },
    menuHeight() {
      const doc = document.documentElement;
      doc.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
    },
    openSignInModal() {
      this.signInModalIsVisible = true;
    },
    clearError() {
      this.error = '';
    },
    closeSignInModal() {
      this.signInModalIsVisible = false;
      this.clearError();
    },
    openOfferDetailsModal(item) {
      this.orderToView = item;
      this.offerDetailsModalIsVisible = true;
    },
    closeOfferDetailsModal() {
      this.offerDetailsModalIsVisible = false;
      this.orderToView = {};
    },
    openSaleSkinModal() {
      this.saleSkinModalIsVisible = true;
    },
    closeSaleSkinModal() {
      this.saleSkinModalIsVisible = false;
    },
    login(data) {
      this.$http.post(process.env.VUE_APP_API + 'login', data)
      .then((res) => {
        if (res.data.status == "OK") {
          this.isAuth = true;
          this.closeSignInModal();
          this.getOrderHistory();
          this.getCartContents();
        } else {
          this.error = res.data.message
        }
      })
      .catch((res) => {
          this.error = res.response.data.message;
      })
    },
    forgotPass(data) {
      let self = this;
      this.$http.post(process.env.VUE_APP_API + 'user/reset-password', data)
      .then((res) => {
        if (res.data.status == "OK") {
          this.successMessage = 'Success';
          this.error = ''
          setTimeout(()=> {
            self.successMessage = '';
          }, 2000)
        } else {
          this.error = res.response.data.message
        }
      })
      .catch((error) => {
        this.error = error.response.data.message
      })
    },
    registration(data) {
      let self = this;
      if (data.password !== data.passConfirm) {
        this.error = 'Password confirmation failed!';
      } else {
        this.$http.post(process.env.VUE_APP_API + 'register', data)
        .then((res) => {
          if (res.data.status == "OK") {
            this.clearError();
            self.isAuth = true;
            self.closeSignInModal();
          } else {
            console.log(res)
            this.error = res.data.message
          }
        })
        .catch((res) => {
            console.log(res);
            this.error = res.response.data.message;
        })
      }
    },
    logout() {
      this.$http.post(process.env.VUE_APP_API + 'logout')
      .then(() => {
        if (this.$route.name != 'Home') {
          this.$router.push({ path: '/' })
        }
        this.isAuth = false;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
    getOrderHistory() {
      this.$http.get(process.env.VUE_APP_API + 'orders/history')
      .then((res) => {
        console.log(res)
        this.orderHistory = res.data.payload
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
    formatDate(num) {
      let objectDate = new Date(num * 1000);
      let day = objectDate.getDate();
      if (day < 10) {
          day = '0' + day;
      }
      let month = objectDate.getMonth() + 1;
      if (month < 10) {
          month = `0${month}`;
      }
      let year = objectDate.getFullYear();

      function padTo2Digits(x) {
        return String(x).padStart(2, '0');
      }
      let hoursAndMinutes = padTo2Digits(objectDate.getHours()) + ':' + padTo2Digits(objectDate.getMinutes());
      let format = day + "." + month + "." + year + ' ' + hoursAndMinutes;

      return format
    },
    removeOneFromCart(item, id) {
      let self = this;

      let cartItem = {
        'id': id,
        'count': '1'
      }
      if (item.count > 1) {
        cartItem = {
          'id': id,
          'count': item.count - 1
        }
      }
      this.$http.post(process.env.VUE_APP_API + 'cart/add', cartItem)
      .then((res) => {
        self.cartContents = res.data.cart;
        this.getCartContents();
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
      
    },
    removeFromCart(item, id) {
      let self = this;

      let cartItem = {
        'id': id
      }

      this.$http.post(process.env.VUE_APP_API + 'cart/remove', cartItem)
      .then((res) => {
        self.cartContents = res.data.cart;
        this.getCartContents();
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
    addToCart(item, id) {
      if (!this.isAuth) {
         this.openSignInModal();
      } else {
        let self = this;

        let cartItem = {
          'id': id,
          'count': 1
        }

        for(var i = 0; i < this.cartContents.length; i++) {
          if (this.cartContents[i].item_id == id) {
              let cartItemCount = this.cartContents[i].count;
              cartItem = {
                'id': id,
                'count': cartItemCount + 1
              }
              break;
          }
        }

        this.$http.post(process.env.VUE_APP_API + 'cart/add', cartItem)
        .then((res) => {
          this.getCartContents();
          self.addToCartChosenItem = cartItem.id;
          self.cartContents = res.data.cart
          setTimeout(()=> {
            self.addToCartChosenItem = '';
          }, 2000)
        })
        .catch((res) => {
          if(res.response.status === 401) {
            this.openSignInModal();
          }
        })
      }
      
    },
    getNewArrivals() {
      this.$http.get(process.env.VUE_APP_API + 'items/list?new=true')
      .then((res) => {
        this.newArrivals = res.data.payload;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
    getRecommended() {
      this.$http.get(process.env.VUE_APP_API + 'items/recommended')
      .then((res) => {
        this.recommendedProducts = res.data.payload;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
    getSale() {
      this.$http.get(process.env.VUE_APP_API + 'items/list?sale=true')
      .then((res) => {
        this.saleProducts = res.data.payload;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
    getCartContents() {
      this.$http.get(process.env.VUE_APP_API + 'cart/contents')
      .then((res) => {
          this.cartContents = res.data.cart;
          
          let total = 0;
          for (let i in this.cartContents) {
            let newPrice;
            if (this.cartContents[i].count > 1) {
              newPrice = this.cartContents[i].count * parseFloat(this.cartContents[i].item.price);
            } else {
              newPrice = parseFloat(this.cartContents[i].item.price);
            }
            total += newPrice;
          }
          this.totalSum = total.toFixed(2);
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
    goToProduct(item) {
      this.$router.push({path: '/products/' + item.id, params: {data: item.id}});
      this.getProductPageContent(item.id);
    },
    getProductPageContent(id) {
      this.productPageItem = '';
      this.similarProducts = '';
      this.$http.get(process.env.VUE_APP_API + 'items/' + id)
      .then((res) => {
        console.log(res)
        this.productPageItem = res.data.payload;
        this.similarProducts = res.data.payload.similar;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    },
    goToPage(item) {
      let newItem;
      if (item == 'privacy') {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].title.toLowerCase().includes('privacy')) {
            newItem = this.textPageList[i];
          } 
        }
      } else if (item == 'terms') {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].title.toLowerCase().includes('terms')) {
            newItem = this.textPageList[i];
          } 
        }
      } else if (item == 'cookie') {
        for (let i = 0; i < this.textPageList.length; i++) {
          if (this.textPageList[i].title.toLowerCase().includes('cookie')) {
            newItem = this.textPageList[i];
          } 
        }
      } else {
        newItem = item
      }
      this.$router.push({path: '/pages/' + newItem.title.replace(/\//g, '').replace(/ /g, '-'), params: {data: newItem.title.replace(/\//g, '').replace(/ /g, '-')}})

      this.getTextPageContent(newItem.id);
      this.closeSignInModal();
    },
    getTextPageContent(id) {
      let newId = '';
      this.textPageTitle = '';
      this.textPageHeader = '';
      this.textPageContent = '';

      if (/-/.test(id)) {
        this.$http.get(process.env.VUE_APP_API + 'static-pages')
        .then((res) => {
          newId = res.data.payload.filter(item => item.title.replace(/\//g, '').replace(/ /g, '-') == id)[0].id;
          this.$http.get(process.env.VUE_APP_API + 'static-pages/' + newId)
          .then((res) => {
            this.textPageTitle = res.data.payload.title;
            this.textPageContent = res.data.payload.content;
            this.textPageHeader = res.data.payload.header;
          })
          .catch((res) => {
            if(res.response.status === 401) {
              this.openSignInModal()
            }
          })
        })
      } else {
        newId = id;
        this.$http.get(process.env.VUE_APP_API + 'static-pages/' + newId)
        .then((res) => {
          this.textPageTitle = res.data.payload.title;
          this.textPageContent = res.data.payload.content;
          this.textPageHeader = res.data.payload.header;
        })
        .catch((res) => {
          if(res.response.status === 401) {
            this.openSignInModal()
          }
        })
      }

      
    },
    getTextPages() {
      this.$http.get(process.env.VUE_APP_API + 'static-pages')
      .then((res) => {
        this.textPageList = res.data.payload
      })
      .catch((res) => {
         if(res.response.status === 401) {
          this.openSignInModal();
        }
      })
    }
  },
  mounted: async function () {
    await this.fetchLanguages();
    this.hostname = this.getDomainName(window.location.hostname)
    this.currency = process.env.VUE_APP_CURRENCY;
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.pageLoaded = true;
    let self = this;
    window.addEventListener("resize", self.menuHeight);
    self.menuHeight();
    
    self.$http.get(process.env.VUE_APP_API + 'is-auth')
    .then((res) => {
        console.log('auth: ' + res.data.authenticated);
        self.isAuth = res.data.authenticated

        if (res.data.authenticated) {
          self.getOrderHistory();
          self.getCartContents();
        }
    })
    .catch((res) => {
        if(res.response.status === 401) {
          this.openSignInModal();
        }
    })


    self.getGoogleTagManagerSnippet();
    
    this.getSale();
    this.getRecommended();
    this.getNewArrivals();
    this.getTextPages();
  },
  computed: {
    
  }
}
</script>
