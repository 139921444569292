<template>
  <div class="slider-container">
    <slick ref="slick"  :options="slickOptions" class="slider products" @swipe="handleSwipe">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="item-wrapper">
          <div class="title-container">
            <div class="desc">{{item.category}}</div>
            <a @click="goToSliderItem(item)" class="title">{{item.title}}</a>
          </div>
          <a @click="goToSliderItem(item)" class="preview">
            <img :src="imgDomain + item.img_url" class="img"/>
            <div class="tag">{{tag}}</div>
            <transition name="fade">
              <span class="title" v-if="$parent.addToCartChosenItem == item.id">{{ $t('Added') }}</span>
            </transition>
          </a>
          <div class="price-container">
            <div class="price title">
              <span class="discount" v-if="item.old_price != '0.00'">{{item.old_price}} <span class="currency">{{$parent.currency}}</span></span>
              <span>{{item.price}} <span class="currency">{{$parent.currency}}</span></span>
            </div>
            <a @click="$parent.addToCart(item, item.id)" class="link">
              <span>{{ $t('Add to Cart') }}</span>
            </a>
          </div>
        </div>
      </div>
    </slick>
  </div>
</template>

<script>

import Slick from 'vue-slick';
export default {
  name: 'SlickItem',
  props: ['list', 'tag'],
  components: {
    Slick
  },
  data: function() {
    return {
      imgDomain: '',
      sliderIsClickble: true,
      slickOptions: {
        centerMode: true,
        variableWidth: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: false,
        infinite: false,
        dots: true,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            }
          },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }
        ]
      },
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
    goToSliderItem(item) {
      let self = this;
      setTimeout(()=> {
        if (self.sliderIsClickble == true) {
          console.log(3)
          self.$parent.goToProduct(item)
        }
      }, 200)
    },
    handleSwipe() {
      let self = this;
      self.sliderIsClickble = false;
      setTimeout(()=> {
        self.sliderIsClickble = true;
      }, 300)
    },
  }
}
</script>