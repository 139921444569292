<template>
  <main class="main home-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title big">{{ $t('Welcome to SkinRiff') }}</div>
          <div class="desc">
            {{ $t('Take a look at all the CS:GO items we have to offer, including skins, knives, gloves, cases, collections, stickers, music kits, and more. If you\'re looking for an easy way to purchase CS:GO skins, stop at CS:GO SkinsRiff.') }}
          </div>
          <router-link to="products" class="button">
            <span>{{ $t('Shop Now') }}</span>
          </router-link>
        </div>
      </div>
      <div class="section daily-offer-section">
        <Features/>
        <div class="wrapper">
          <div class="center">
            <div class="title">{{ $t('Daily Offer') }}</div>
            <div class="countdown" v-if="dailyOffer && dailyOffer.length">
              <div class="column">
                <div class="num">6</div>
                <div class="desc">{{ $t('Days') }}</div>
              </div>
              <div class="column">
                <div class="num">5</div>
                <div class="desc">{{ $t('Hours') }}</div>
              </div>
              <div class="column">
                <div class="num">39</div>
                <div class="desc">{{ $t('Minutes') }}</div>
              </div>
              <div class="column">
                <div class="num">15</div>
                <div class="desc">{{ $t('Seconds') }}</div>
              </div>
            </div>
          </div>
          <div class="preview" v-if="dailyOffer.img_url" @click="$parent.goToProduct(dailyOffer)">
            <img :src="imgDomain + dailyOffer.img_url" class="img"/>
          </div>
          <div v-if="dailyOffer && dailyOffer.length" class="right" @click="$parent.goToProduct(dailyOffer)">
            <div class="desc">{{dailyOffer.type}}</div>
            <div class="title small">{{dailyOffer.title}}</div>
            <div class="title price">
              {{dailyOffer.price}}
              <span class="currency">{{currency}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="section arrivals-section">
        <div class="top">
          <div class="title">
            <span>{{ $t('NEW') }}</span> {{ $t('Arrivals') }}
          </div>
          <router-link to="/new-arrivals" class="link">
            <span>{{ $t('View more') }}</span>
            <img class="img" src="./../assets/right-arrow.svg"/>
          </router-link>
        </div>
        <div class="wrapper">
          <SlickItem v-if="$parent.newArrivals.length" :list="$parent.newArrivals" :tag="'Valve'" />
        </div>
      </div>
      <div class="section more-offers-section">
        <div class="wrapper">
          <div class="left">
            <div class="title big">{{ $t('More than') }} <span>{{ $t('1K') }}</span> {{ $t('offers') }}</div>
          </div>
          <div class="left">
            
            <ul class="list">
              <li class="item desc">
                {{ $t('There are many variations of skins to choose from') }}
              </li>
              <li class="item desc">
                {{ $t('Cheap CS:GO skins at a discounted price') }}
              </li>
              <li class="item desc">
                {{ $t('It is your chance to get Dragon lore and other epic items!') }}
              </li>
            </ul>
            <router-link to="products" class="button">
              <span>{{ $t('View all offers') }}</span>
            </router-link>
          </div>
        </div>
      </div>
      <div class="section slider-section">
        <div class="slider-container" v-if="$parent.newArrivals.length">
          <slick ref="slick" :options="slickCompact" @init="handleInit" @swipe="handleSwipe" class="slider-small products">
            <div class="item" v-for="(item, index) in $parent.newArrivals" :key="index">
              <a @click="goToSliderItem(item)" :class="['item-wrapper', {'disabled': !sliderIsClickble}]">
                <div class="preview">
                  <img :src="imgDomain + item.img_url" class="img"/>
                </div>
                <div class="text">
                  <div class="desc">{{item.category}}</div>
                  <div class="title">{{item.title}}</div>
                </div>
              </a>
            </div>
          </slick>
        </div>
      </div>
      <div class="section arrivals-section sale-section">
        <div class="wrapper">
          <div class="top">
            <div class="title">
              {{ $t('Sale') }}
            </div>
            <router-link to="/sale" class="link">
              <span>{{ $t('View more') }}</span>
              <img class="img" src="./../assets/right-arrow.svg"/>
            </router-link>
          </div>
          <SlickItem v-if="$parent.saleProducts.length" :list="$parent.saleProducts" :tag="$t('Sale')" />
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import Slick from 'vue-slick';
import SlickItem from '../components/SlickItem.vue';
import Features from '../components/Features.vue'

export default {
  name: 'Home',
  props: ['addToCartChosenItem', 'currency'],
  components: {
    Slick,
    SlickItem,
    Features
  },
  data: function() {
    return {
      imgDomain: '',
      dailyOffer: {},
      sliderIsClickble: true,
      slickCompact: {
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: false,
        arrows: false,
        infinite: false,
        dots: false,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 900,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          }
        ]
      }
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.getDailyOffer();
  },
  methods: {
    getDailyOffer() {
      this.$http.get(process.env.VUE_APP_API + 'items/daily')
      .then((res) => {
        console.log(res)
        this.dailyOffer = res.data.payload;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    goToProduct(item) {
      this.$parent.goToProduct(item);
    },
    goToSliderItem(item) {
      let self = this;
      setTimeout(()=> {
        if (self.sliderIsClickble == true) {
          console.log(3)
          self.$parent.goToProduct(item)
        }
      }, 200)
    },
    addToCart(item, id) {
      this.$parent.addToCart(item, id);
    },
    handleSwipe() {
      let self = this;
      self.sliderIsClickble = false;
      setTimeout(()=> {
        self.sliderIsClickble = true;
      }, 300)
    },
    handleInit(event, slick) {
        console.log('handleInit', event, slick);
    }
  }
}
</script>