<template>
  <main class="main csgo-products-page page-inside">
    <div class="main-wrapper">
      <section class="section hero-section sale-section product-list-section">
        <div class="wrapper">
          <div class="title big">{{ $t('Sale') }}</div>
          <div class="list products sale-products">
            <div class="item" v-for="(item, index) in $parent.saleProducts" :key="index">
              <div class="item-wrapper">
                <div class="desc">{{item.category}}</div>
                <a @click="$parent.goToProduct(item)" class="title">{{item.title}}</a>
                <a @click="$parent.goToProduct(item)" class="preview">
                  <img :src="imgDomain + item.img_url" class="img"/>
                  <div class="tag">{{ $t('Sale') }}</div>
                  <transition name="fade">
                    <span class="title" v-if="$parent.addToCartChosenItem == item.id">{{ $t('Added') }}</span>
                  </transition>
                </a>
                <div class="price-container">
                  <div class="price title">
                    <span class="discount" v-if="item.old_price != '0.00'">{{item.old_price}} {{$parent.currency}}</span>
                    <span>{{item.price}} <span class="currency">{{$parent.currency}}</span></span>
                  </div>
                  <a class="link" @click="$parent.addToCart(item, item.id)">
                    <span>{{ $t('Add to Cart') }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Features/>
      </section>
    </div>
  </main>
</template>
<script>
import Features from '../components/Features.vue';

export default {
  name: 'Sale',
  props: [],
  components: {
    Features
  },
  data: function() {
    return {
      imgDomain: ''
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  methods: {
  }
}
</script>