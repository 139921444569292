<template>
  <main class="main cart-page page-inside" v-if="$parent.isAuth">
    <div class="main-wrapper">
      <section class="section hero-section">
        <div class="wrapper">
          <div class="title big">{{ $t('Cart') }}</div>
          <div class="left">
            <div class="product-list">
              <div class="item" v-for="(item, i) in $parent.cartContents" :key="i">
                <div class="preview">
                  <img :src="imgDomain + item.item.img_url" class="img"/>
                </div>
                <div class="title-container">
                  <div class="title">{{item.item.title}}</div>
                  <div>
                    <div class="count">
                      <div class="count__item minus" @click="$parent.removeOneFromCart(item, item.item_id)"></div>
                      <input type="number" value="1" v-model="item.count"/>
                      <div class="count__item plus" @click="$parent.addToCart(item.item, item.item_id)"></div>
                    </div>
                    <div class="price">{{item.item.price}} <span class="currency">{{$parent.currency}}</span></div>
                  </div>
                </div>
                <div class="delete" @click="$parent.removeFromCart(item.item, item.item_id)">
                  <img src="./../assets/close.svg" class="img"/>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="wrapper">
              <div class="title small">{{ $t('Contact information') }}</div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>{{ $t('First name:') }}</div>
                <input type="text" :placeholder="$t('First name')" v-model="name"/>
              </div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>{{ $t('Last name:') }}</div>
                <input type="text" :placeholder="$t('Last name')" v-model="surname"/>
              </div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>{{ $t('Phone:') }}</div>
                <input type="tel" :placeholder="$t('Phone')" v-model="phone"/>
              </div>
              <div class="input-container steam-input-container">
                <div class="desc"><span class="red">*</span>{{ $t('Steam link:') }}</div>
                <input type="text" :placeholder="$t('Steam link')" v-model="tradeUrl"/>
              </div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>{{ $t('Country:') }}</div>
                <Select2 class="select2 single" v-model="country" 
                :options="countryOptions" 
                @select="selectCountry($event)"
                placeholder="Country"
                 />
              </div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>{{ $t('City:') }}</div>
                <input type="text" :placeholder="$t('City')" v-model="city"/>
              </div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>{{ $t('Address:') }}</div>
                <input type="text" :placeholder="$t('Address')" v-model="address"/>
              </div>
              <div class="input-container">
                <div class="desc"><span class="red">*</span>{{ $t('Post Code:') }}</div>
                <input type="text" :placeholder="$t('Post Code')" v-model="postCode"/>
              </div>
              <div class="input-container">
                <div class="chekbox-container">
                  <label class="chekbox-label">
                    <div class="chekbox-label-wrapper">
                      <input type="checkbox" name="terms" v-model="terms"/>
                      <div class="checkbox"></div>
                      <span class="title">{{ $t('I agree with') }} </span>
                      <a @click="$parent.goToPage('privacy')" class="title">{{ $t('privacy policy') }} </a>
                      <span class="title"> {{ $t('and') }} </span>
                      <a @click="$parent.goToPage('terms')" class="title">{{ $t('terms and conditions') }}</a>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div class="wrapper">
              <div class="title small">{{ $t('Payment Method') }}</div>
              <div class="row">
                <div class="desc">{{ $t('Total to pay:') }}</div>
                <div class="title small">{{$parent.totalSum}} <span class="currency">{{$parent.currency}}</span></div>
              </div>
              <button @click="submit('applepay')" :class="['button apple-pay', {'disabled': !requiredFieldsAreFilled}]">
                <img src="./../assets/apple-pay.svg" class="img applepay"/>
              </button>
              <button @click="submit('googlepay')" :class="['button google-pay', {'disabled': !requiredFieldsAreFilled}]">
                <img src="./../assets/google-pay.svg" class="img googlepay"/>
              </button>
              <button @click="submit('card')" :class="['button', {'disabled': !requiredFieldsAreFilled}]">
                <span>{{ $t('Credit / Debit cards') }}</span>
              </button>
              <transition name="fade">
                <div class="desc red" v-if="error">{{$t(error)}}</div>
              </transition>
            </div>
          </div>
        </div>
        <Features/>
      </section>
    </div>
  </main>
</template>
<script>
import Select2 from 'vue3-select2-component';
import Features from '../components/Features.vue';

export default {
  name: 'Cart',
  props: [],
  components: {
    Select2,
    Features
  },
  data: function() {
    return {
      imgDomain: '',
      countryOptions: [],
      countryOptionsId: [],
      name: '',
      surname: '',
      phone: '',
      tradeUrl: '',
      country: '',
      countryId: '',
      city: '',
      address: '',
      postCode: '',
      terms: false,
      error: ''
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.getProfile();
    this.getCounties();
  },
  computed: {
    requiredFieldsAreFilled() {
      let fieldsAreFilled;
      if(
        this.name && this.surname  && this.phone && 
        this.tradeUrl && this.country && 
        this.city && this.address  
        && this.postCode && this.terms 
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  methods: {
    selectCountry(event) {
      this.country = event.text;
      for (let i = 0; i < this.countryOptions.length; i++) {
        if (this.countryOptions[i] == event.text) {
          this.countryId = this.countryOptionsId[i];
        }
      }
      console.log(this.countryId)
    },
    submit(type) {
      let data = {
        "name": this.name,
        "surname": this.surname,
        "phone": this.phone,
        "tradeUrl": this.tradeUrl,
        "country": this.countryId,
        "city": this.city,
        "address": this.address,
        "postCode": this.postCode,
        "paymentType": type,
      }
      this.$http.post(process.env.VUE_APP_API + 'orders/checkout', data)
      .then((res) => {
          console.log(res);
          window.location = res.data.redirect_url;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
        this.error = res.response.data.message;
        setTimeout(() => {
          this.error = '';
        }, 3000);
      })
    },
    getProfile() {
      this.$http.get(process.env.VUE_APP_API + 'user/profile')
      .then((res) => {
          this.name = res.data.name;
          this.surname = res.data.surname;
          this.phone = res.data.phone;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    getCounties() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
      .then((res) => {
          let arr = res.data.payload;
          let newCountries = arr.map(function(item) {
            return item['title'];
          });
          let newCountriesId = arr.map(function(item) {
            return item['id'];
          });
          this.countryOptionsId = newCountriesId;
          this.countryOptions = newCountries;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    }
  }
}
</script>