<template>
	<header class="header">
        <div class="wrapper">
          <router-link to="/" class="logo">
            <img src="./../assets/logo.svg" class="img"/>
          </router-link>
          <ul class="nav">
            <template v-for="(item, i) in $parent.nav">
              <li class="nav__item" :key="i">
                <router-link :to="item.link">{{ $t(item.title) }}</router-link>
              </li>
            </template>
          </ul>
          <div class="right">
            <LanguageSelectDropdown />
            <router-link to="/cart" v-if="$parent.isAuth" class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="23.722" viewBox="0 0 26 23.722">
                <g id="empty-cart" transform="translate(0 -5.111)">
                  <path id="Контур_217" data-name="Контур 217" d="M104.3,108.657a.826.826,0,0,0,.821.741.841.841,0,0,0,.086,0,.826.826,0,0,0,.737-.906l-.381-3.686a.826.826,0,1,0-1.643.17Z" transform="translate(-92.261 -90.703)" fill="#363d47"/>
                  <path id="Контур_218" data-name="Контур 218" d="M148.362,109.393a.835.835,0,0,0,.086,0,.826.826,0,0,0,.82-.741l.381-3.686a.826.826,0,0,0-1.643-.17l-.381,3.686A.826.826,0,0,0,148.362,109.393Z" transform="translate(-130.462 -90.703)" fill="#363d47"/>
                  <path id="Контур_219" data-name="Контур 219" d="M75.619,185.088a2.557,2.557,0,1,0,2.557,2.556A2.559,2.559,0,0,0,75.619,185.088Zm0,3.462a.9.9,0,1,1,.9-.905A.906.906,0,0,1,75.619,188.55Z" transform="translate(-65.126 -161.368)" fill="#363d47"/>
                  <path id="Контур_220" data-name="Контур 220" d="M153.06,185.088a2.557,2.557,0,1,0,2.557,2.556A2.559,2.559,0,0,0,153.06,185.088Zm0,3.462a.9.9,0,1,1,.9-.905A.906.906,0,0,1,153.06,188.55Z" transform="translate(-132.172 -161.368)" fill="#363d47"/>
                  <path id="Контур_221" data-name="Контур 221" d="M37.181,50.825a.91.91,0,0,0-.739-.393H17.824l-.782-3.26a.951.951,0,0,0-.9-.758H12.31a1.016,1.016,0,0,0,0,2.025h3.106l.776,3.233c0,.018.008.036.013.054l2.884,12.024a.951.951,0,0,0,.9.758H33.551a.951.951,0,0,0,.9-.758L37.346,51.7A1.081,1.081,0,0,0,37.181,50.825ZM32.829,62.483H20.715l-2.4-10.026H35.234Z" transform="translate(-11.376 -41.303)" fill="#363d47"/>
                </g>
              </svg>
              <div class="indicator">
                <div class="triangle"></div>
                {{cartContents.length}}
              </div>
            </router-link>
            <div class="link-container dropdown-open" v-if="$parent.isAuth">
              <div class="icon">
                <svg id="user_3_" data-name="user (3)" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32">
                  <defs>
                    <linearGradient id="linear-gradient" x1="0.5" y1="1" x2="0.5" y2="0.269" gradientUnits="objectBoundingBox">
                      <stop offset="0" stop-color="#3b22af"/>
                      <stop offset="1" stop-color="#8415d9"/>
                    </linearGradient>
                  </defs>
                  <g id="Сгруппировать_29" data-name="Сгруппировать 29" transform="translate(0 0)">
                    <path id="Контур_225" data-name="Контур 225" d="M16,0A16,16,0,1,0,32,16,16.062,16.062,0,0,0,16,0Zm0,9.437a4.693,4.693,0,0,1,4.685,4.532,4.908,4.908,0,0,1-4.432,4.836,4.686,4.686,0,0,1-4.94-4.68A4.693,4.693,0,0,1,16,9.437Zm8.437,17.848a14.076,14.076,0,0,1-1.875,1.2V25.437a.938.938,0,0,0-1.875,0V29.3a13.8,13.8,0,0,1-9.375,0V25.437a.938.938,0,0,0-1.875,0v3.053a14.07,14.07,0,0,1-1.875-1.2V25.437A4.693,4.693,0,0,1,12.25,20.75h7.5a4.693,4.693,0,0,1,4.688,4.688Z" transform="translate(0 0)" fill="url(#linear-gradient)"/>
                  </g>
                </svg>
              </div>
              <div class="desc">{{ $t('My account') }}</div>
              <div class="arrow">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7.139" viewBox="0 0 12 7.139">
                  <path id="Контур_226" data-name="Контур 226" d="M-1983.585-1267.446a1.14,1.14,0,0,1-.812-.335l-4.852-4.852a1.143,1.143,0,0,1,0-1.617,1.143,1.143,0,0,1,1.617,0l4.048,4.048,4.048-4.048a1.143,1.143,0,0,1,1.617,0,1.143,1.143,0,0,1,0,1.617l-4.852,4.852a1.14,1.14,0,0,1-.809.335Z" transform="translate(1989.585 1274.586)" fill="#363d47"/>
                </svg>
              </div>
              <div class="dropdown">
                <div class="nav__item">
                  <router-link to="/profile">
                    {{ $t('Overview') }}
                  </router-link>
                </div>
                <!-- <div class="nav__item">
                  <router-link to="sell-offers">
                    Sell Offers
                  </router-link>
                </div> -->
                <div class="nav__item nav__item-logout">
                  <a @click="logout">
                    {{ $t('Log Out') }}
                  </a>
                </div>
              </div>
            </div>
            <div class="button modal-open" id="signInBtn" @click="$emit('openSignInModal')" v-else>
              <span>{{ $t('Login') }}</span>
              <div class="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="20.022" height="20.022" viewBox="0 0 20.022 20.022">
                  <g id="login-" transform="translate(-1.989 -1.989)">
                    <path id="Контур_189" data-name="Контур 189" d="M18.328,1.989H5.672A3.687,3.687,0,0,0,1.989,5.672V7.08a1,1,0,0,0,2,0V5.672A1.685,1.685,0,0,1,5.672,3.989H18.328a1.685,1.685,0,0,1,1.683,1.683V18.328a1.685,1.685,0,0,1-1.683,1.683H5.672a1.685,1.685,0,0,1-1.683-1.683v-1.4a1,1,0,0,0-2,0v1.4a3.687,3.687,0,0,0,3.683,3.683H18.328a3.687,3.687,0,0,0,3.683-3.683V5.672a3.688,3.688,0,0,0-3.683-3.683Z" fill="#363d47"/>
                    <path id="Контур_190" data-name="Контур 190" d="M2.042,12a1,1,0,0,0,1,1h8.547L10.3,14.3A1,1,0,1,0,11.71,15.71l3-3a1,1,0,0,0,0-1.415l-3-3A1,1,0,0,0,10.3,9.71L11.589,11H3.042a1,1,0,0,0-1,1Z" fill="#363d47"/>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
    </header>
</template>

<script>
import LanguageSelectDropdown from '@/components/LanguageSelectDropdown.vue'

export default {
	name: 'Header',
  props: ['cartContents'],
	data: function() {
		return {
		}
	},
  components: {
    LanguageSelectDropdown
  },
	methods: {
    openSignInModal() {
      this.$emit('openSignInModal')
    },
    openSaleSkinModal() {
      this.$emit('openSaleSkinModal')
    },
    logout() {
      this.$emit('logout')
    }
	}
}
</script>
