<template>
  <main class="main csgo-products-page product-page page-inside">
    <div class="main-wrapper">
      <section class="section hero-section product-list-section">
        <div class="wrapper">
          <div class="title productPage-title small">
            <router-link to="/products" class="back">
              <img src="./../assets/back.svg" class="img"/>
              <span class="desc">{{ $t('Back') }}</span>
            </router-link>
            {{$parent.productPageItem.title}}
          </div>
          <transition name="fade">
            <div class="product-preview" v-if="$parent.productPageItem">
              <div class="preview-container">
                <div class="preview">
                  <transition name="fade">
                    <span class="title" v-if="$parent.addToCartChosenItem == $parent.productPageItem.id">{{ $t('Added') }}</span>
                  </transition>
                  <img :src="$parent.imgDomain + $parent.productPageItem.img_url" class="img"/>
                </div>
              </div>
              <div class="text">
                <div class="row">
                  <div class="desc">{{ $t('Type:') }}</div>
                  <div class="desc">{{$parent.productPageItem.type}}</div>
                </div>
                <div class="row">
                  <div class="desc">{{ $t('Quality:') }}</div>
                  <div class="desc">{{$parent.productPageItem.quality}}</div>
                </div>
                <div class="row row-price">
                  <div class="desc">{{ $t('Price:') }}</div>
                  <div class="title">{{$parent.productPageItem.price}} <span class="currency">{{currency}}</span></div>
                </div>
                <button class="button long" @click="$parent.addToCart($parent.productPageItem, $parent.productPageItem.id)">
                  <span>{{ $t('Add to Cart') }}</span>
                </button>
              </div>
            </div>
          </transition>
        </div>
      </section>
      <div class="section arrivals-section recommended-section">
        <div class="wrapper">
          <div class="top">
            <div class="title">
              {{ $t('Similar Products') }}
            </div>
            <router-link to="/recommend" class="link">
              <span>{{ $t('View more') }}</span>
              <img class="img" src="./../assets/right-arrow.svg"/>
            </router-link>
          </div>
          <SlickItem v-if="$parent.similarProducts.length" :list="$parent.similarProducts" :tag="$t('We Recommend')" />
        </div>
        <Features/>
      </div>
    </div>
  </main>
</template>
<script>
import SlickItem from '../components/SlickItem.vue';
import Features from '../components/Features.vue';
export default {
  name: 'ProductPage',
  props: ['addToCartChosenItem', 'currency'],
  components: {
    SlickItem,
    Features
  },
  data: function() {
    return {
      
    }
  },
  created() {
  },
  mounted() {
    
    this.$parent.getProductPageContent(this.$route.params.id);
  },
  methods: {
    addToCart(item, id) {
      this.$parent.addToCart(item, id);
    },
    goToProduct(item) {
      this.$parent.goToProduct(item);
    }
  }
}
</script>