<template>
	<div class="features-section">
		<ul class="list">
		<li class="item">
			<div class="icon">
			<img src="./../assets/shield.svg" class="img"/>
			</div>
			<div class="title">{{ $t('100% Secure') }}</div>
			<div class="desc small">{{ $t('Your funds and skins will be protected since all trades are closely watched.') }}</div>
		</li>
		<li class="item">
			<div class="icon">
			<img src="./../assets/layer.svg" class="img"/>
			</div>
			<div class="title">{{ $t('Wide Choice') }}</div>
			<div class="desc small">{{ $t('Unique and high-quality skins come in a wide variety.') }}</div>
		</li>
		<li class="item">
			<div class="icon">
			<img src="./../assets/discount.svg" class="img"/>
			</div>
			<div class="title">{{ $t('Best Prices') }}</div>
			<div class="desc small">{{ $t('In CS:GO, aesthetics are more important than flash grenades and headshots') }}</div>
		</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'Features',
	props: [],
	data: function() {
		return {
			
		}
	},
	methods: {
		
	},
}
</script>
